/** @jsxRuntime classic */
/** @jsx jsx */
import React from 'react';

import { jsx, css } from '@emotion/react';
import ReactTooltip from 'react-tooltip';

import Container from '../Container/Container';
import { Estimate as IEstimate } from '../../services/estimate';
import {
  average,
  estimateItem,
  heading,
  highest,
  label,
  left,
  right,
  lowest,
  value,
  wrapper,
  estimateCSS,
  tooltipTrigger,
  tooltip,
  headingInner
} from './Estimate.style';
import { money } from '../../utils/format';
import Spinner from '../Spinner/Spinner';

interface Props {
  estimate: IEstimate | null;
  isLoading?: boolean;
  CTAText: string;
}

const getTooltipValue = (CTAText: string) =>
  `These estimates are based on general characteristics of the property. 
  To get personalized quotes from 30+ A rated carriers, click ${CTAText}. Credit is not used where prohibited.`;

const SpinnerAbsolute = () => (
  <div
    css={css`
      position: absolute;
      right: 0;
    `}
  >
    <Spinner />
  </div>
);

const Estimate: React.FC<Props> = ({ estimate, isLoading, children, CTAText }) => (
  <div css={estimateCSS}>
    <Container>
      
      <div css={wrapper}>
        <div css={left}>
          <h2 css={heading}>
            <span css={headingInner}>
              <span>Estimated Premium</span>
              <span css={tooltipTrigger} data-tip={getTooltipValue(CTAText)}>
                ?
              </span>
            </span>
            <ReactTooltip css={tooltip} />
          </h2>
          <div css={estimateItem}>
            <div css={[label, lowest]}>Lowest Quote</div>
            <div css={value}>
              {isLoading ? <SpinnerAbsolute /> : estimate?.min ? money(estimate?.min, 0, '$', '/yr') : '$ - - -'}
            </div>
          </div>
          <div css={estimateItem}>
            <div css={[label, average]}>Average Quote</div>
            <div css={value}>
              {isLoading ? <SpinnerAbsolute /> : estimate?.avg ? money(estimate?.avg, 0, '$', '/yr') : '$ - - -'}
            </div>
          </div>
          <div css={estimateItem}>
            <div css={[label, highest]}>Highest Quote</div>
            <div css={value}>
              {isLoading ? <SpinnerAbsolute /> : estimate?.max ? money(estimate?.max, 0, '$', '/yr') : '$ - - -'}
            </div>
          </div>
        </div>
        <div css={right}>
          {children}
        </div>
      </div>
    </Container>
  </div>
);

export default Estimate;
