/** @jsxRuntime classic */
/** @jsx jsx */
import React, { forwardRef, Fragment, useState } from 'react';

import { jsx } from '@emotion/react';

import {
  editModeWrapper,
  viewModeWrapper,
  wrapper,
  editBtn,
  text,
  input,
  errorMsg,
  mobileLabel as mobileLabelCSS,
  label as labelCSS
} from './InputGroup.style';
import editIcon from '../../assets/edit.svg';
import Spinner from '../Spinner/Spinner';

interface Props {
  label: string;
  mobileLabel?: string;
  value?: number | null;
  name: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  errorMessage?: string;
}

const InputGroup = forwardRef<HTMLInputElement, Props>(({
  label,
  value,
  name,
  errorMessage,
  mobileLabel,
  isLoading = false,
  isDisabled = false,
  ...props
}, ref) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const TextWrapper = isEditMode ? 'label' : 'span';

  return (
    <Fragment>
      <div css={wrapper}>
        <TextWrapper css={text} htmlFor={name}>
          {mobileLabel && <span css={mobileLabelCSS}>{mobileLabel}</span>}
          <span css={labelCSS(!!mobileLabel)}>{label}</span>
        </TextWrapper>
        {isLoading ? (
          <Spinner />
        ) : (
          <Fragment>
            <span css={viewModeWrapper(isEditMode)}>
              <span css={text}>{value ? value : '- - -'}</span>
              <button type="button" css={editBtn} onClick={() => setIsEditMode(true)}>
                <img src={editIcon} alt="Edit" />
              </button>
            </span>
            <span css={editModeWrapper(isEditMode)}>
              {errorMessage && <span css={errorMsg}>{errorMessage}</span>}
              <input
                type="number"
                id={name}
                css={input(!!errorMessage)}
                disabled={isDisabled}
                ref={ref}
                name={name}
                {...props}
              />
            </span>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
});

export default InputGroup;
