/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

import React, { useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import { CommonTextProps } from '../../interfaces/common.types';
import analytics from '../../utils/analytics';
import { getFormConfigParams } from '../../utils/config';
import FakeInput from '../FakeInput/FakeInput';
import { buttonCSS, copyContainerCSS } from './Copy.style';

const Copy: React.FC<CommonTextProps>  = ({ text }) => {
  const tipRef = useRef(null);
  const { partner_key, placement } = getFormConfigParams();

  const copy = (): void => {
    navigator.clipboard.writeText(text);
    analytics.track('Copy Link Button Clicked', { partner_key, placement });
    ReactTooltip.show(tipRef.current!);
  };

  const hide = (): void => {
    setTimeout(() => {
      ReactTooltip.hide(tipRef.current!);
    }, 1000);
  };

  return (
    <div css={copyContainerCSS} >
      <span data-tip='Link Copied to Clipboard' ref={tipRef} data-for='copy-tip' />
      <ReactTooltip 
        afterShow={hide} 
        id='copy-tip'
        offset={{right: 125}}
      />

      <FakeInput text={text} /> 
      <button css={buttonCSS} onClick={copy}> 
        Copy
      </button>
    </div>
  );
};

export default Copy;
