import { css, SerializedStyles } from '@emotion/react';

import CSS from '../../constants/cssVariables';

export const wrapper = css`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
  align-items: center;
  min-height: 32px;
`;

export const viewModeWrapper = (isEditMode: boolean): SerializedStyles => css`
  display: ${isEditMode ? 'none' : 'inline'};
`;

export const editModeWrapper = (isEditMode: boolean): SerializedStyles => css`
  display: ${isEditMode ? 'inline' : 'none'};
`;

export const editBtn = css`
  background-color: transparent;
  -webkit-appearance: none;
  border: none;
  cursor: pointer;
  margin-left: 5px;
`;

export const text = css`
  font-size: 14px;
  line-height: 1.35;

  ${CSS.mq[0]} {
    font-size: 16px;
    line-height: 1.25;
  }
`;

export const input = (hasError: boolean): SerializedStyles => css`
  -webkit-appearance: none;
  border: 1px solid ${hasError ? '#C80000' : '#000'};
  font-size: 16px;
  height: 28px;
  padding-left: 12px;
  max-width: 50px;
  border-radius: 4px;
  outline: none;
  font-variant-numeric: lining-nums;
  
  &,
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  
  &:focus {
    border-color: ${hasError ? '#C80000' : '#408DF7'};
  }
`;

export const errorMsg = css`
  font-size: 12px;
  color: #C80000;
  margin-right: 5px;
`;

export const mobileLabel = css`
  ${CSS.mq[0]} {
    display: none;
  }
`;

export const label = (hasMobileLabel: boolean): SerializedStyles => css`
  display: ${hasMobileLabel ? 'none' : 'inline'};
  
  ${CSS.mq[0]} {
    display: inline;
  }
`;
