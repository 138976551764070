/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';

import { css, jsx } from '@emotion/react';

interface Props {
  name: string;
  value: string;
}

const HiddenInput: React.FC<Props> = ({ name, value }) => (
  <input
    type="text"
    css={css`
      display: none;
    `}
    name={name}
    onChange={() => null}
    value={value}
    data-testid="hidden-input"
  />
);

export default HiddenInput;
