/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';

import { jsx, css, SerializedStyles } from '@emotion/react';

import Container from '../Container/Container';
import CSS from '../../constants/cssVariables';
import { getConfigParams, subDomainResolver } from '../../utils/config';
import { PartnerNames } from '../../constants/partnerConfig';
import getHeadingConfig from '../../utils/headingConfig';

const header = css`
  background-color: ${CSS.grayLight};
  padding-bottom: 16px;
  padding-top: 14px;

  ${CSS.mq[0]} {
    padding-bottom: 20px;
    padding-top: 19px;
  }
`;

const headingCSS = css`
  font-size: 20px;
  line-height: 1.4;
  font-weight: 700;
  margin: 9px 0 0;
  
  ${CSS.mq[0]} {
    font-size: 24px;
  }
`;

const logoCSS = (biggerImg: boolean): SerializedStyles => css`
  position: relative;
  display: flex;

  & img {
    max-width: ${biggerImg ? 156 : 76}px;
  }

  &:nth-of-type(2)::before {
    position: absolute;
    content: '';
    width: 1px;
    height: 28px;
    height: 100%;
    background-color: black;
    left: -12px;
  }
`;

const logosWrapperCSS = css`
  display: flex;
  position: relative;
  gap: 24px;
`;

const isFreedomLoanOfficer = subDomainResolver() === PartnerNames.FreedomMortgage;

const Header: React.FC = () => {
  const { partner_key } = getConfigParams();
  const { heading, logo } = getHeadingConfig(partner_key as PartnerNames);
  
  return (
    <header css={header}>
      <Container>
        <div css={logosWrapperCSS}>
          {
            isFreedomLoanOfficer && <div css={logoCSS(false)}>
              <img src='https://maticinsurance.sirv.com/partners/freedom.png' alt='Freedom Logo' />
            </div>
          }
          <div css={logoCSS(partner_key as PartnerNames === PartnerNames.NewAmericanFunding)}>
            <img src={logo.logoUrl} alt={logo.alt}  /> 
          </div>
        </div>
        <h2 css={headingCSS}>
          {
            isFreedomLoanOfficer ? 
            'Home Insurance Estimator' : 
            heading
          }
        </h2>
      </Container>
    </header>
  );
};

export default Header;
