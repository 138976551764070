/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';

import debounce from 'debounce-promise';
import { jsx } from '@emotion/react';

import streetsService from '../../services/streets';
import AddressOption, { getOptionString } from './AddressOption';
import { AddressAnswer, AddressSuggestion, SuggestionResponse } from '../../interfaces/IStreets';
import {
  menu,
  menuList,
  input,
  inputWrapper,
  label,
  labelText,
  errorCSS,
  loading,
  labelWrapper
} from './Address.style';

interface Props {
  onSelectEntry(answer: AddressAnswer | string | null): void;
  onValidEntry(answer: AddressAnswer | string | null): void;
  value?: AddressAnswer;
  error?: string;
}

interface Options {
  data: AddressSuggestion[];
  isLoading: boolean;
}

const getDefaultValueString = (value: any): string =>
  value && (value as AddressAnswer).address1
    ? `${value.address1} ${value.city}, ${value.state} ${value.zip}`.trim()
    : '';

const suggestionToAnswer = (suggestion: AddressSuggestion): AddressAnswer => ({
  address1: `${suggestion.street_line} ${suggestion.secondary}`.trim(),
  city: suggestion.city,
  state: suggestion.state,
  zip: suggestion.zipcode
});

const Address: React.FC<Props> = ({ onValidEntry = () => null, onSelectEntry = () => null, value, error }) => {
  const addressEl = useRef<HTMLInputElement>(null);
  const inputEl = useRef<HTMLInputElement>(null);
  const [isFocused, setFocused] = useState(false);
  const [selected, setSelected] = useState<string | null>(null);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [isInvalid, setInvalid] = useState(false);
  const [options, setOptions] = useState<Options>({ data: [], isLoading: false });
  const dataRef = useRef({ opts: options.data, defaultValue: '', focusedIndex });
  dataRef.current.opts = options.data;
  dataRef.current.defaultValue = useMemo(() => getDefaultValueString(value), [value]);
  dataRef.current.focusedIndex = focusedIndex;

  const isOpen = isFocused && (!!options.data.length || options.isLoading);
  const errorMsg = 'Please enter a valid address.';

  const onInput = useMemo(
    () =>
      debounce((inputValue: string) => {
        if (inputValue) {
          document.activeElement === inputEl.current && setFocused(true);
          setOptions(options => ({ ...options, isLoading: true }));

          streetsService
            .suggest(inputValue)
            .then((response: SuggestionResponse) => {
              setFocusedIndex(-1);
              setOptions({ data: response.data.suggestions || [], isLoading: false });
            })
            .catch(() => {
              setFocusedIndex(-1);
              setOptions({ data: [], isLoading: false });
            });
        }
      }, 1000),
    []
  );

  const onSelect = useCallback(
    (option: AddressSuggestion, optionString: string) => {
      if (+option.entries > 1) {
        setOptions(options => ({ ...options, isLoading: true }));
        streetsService
          .suggestSecondary(inputEl.current?.value as string, optionString)
          .then((response: SuggestionResponse) =>
            setOptions({ data: response.data.suggestions || [], isLoading: false })
          )
          .catch(() => setOptions({ data: [], isLoading: false }));

        if (inputEl.current) {
          inputEl.current.value = option.street_line;
          inputEl.current.focus();
        }
      } else {
        if (inputEl.current) inputEl.current.value = optionString;
        onSelectEntry(suggestionToAnswer(option));
        setFocused(false);
        setInvalid(false);
        setSelected(getDefaultValueString(suggestionToAnswer(option)));
      }
    },
    [onSelectEntry]
  );

  const onKeyPress = useCallback(
    (e: KeyboardEvent) => {
      switch (e.code) {
        case 'ArrowDown':
          setFocusedIndex(i => (dataRef.current.opts.length === ++i ? -1 : i));
          break;
        case 'ArrowUp':
          setFocusedIndex(i => (i === -1 ? dataRef.current.opts.length - 1 : --i));
          break;
        case 'Enter':
          const option = dataRef.current.opts[dataRef.current.focusedIndex];
          !!option && onSelect(option, getOptionString(option));
          break;
        case 'Escape':
          inputEl.current && inputEl.current.blur();
          setFocused(false);
          break;
        default:
          return;
      }
    },
    [onSelect]
  );

  const onBlur = useCallback(
    e => {
      if (!addressEl.current?.contains(e.target)) {
        const inputValue = inputEl.current?.value.trim();
        document.removeEventListener('keydown', onKeyPress);
        setFocused(false);
        setInvalid(!inputValue || inputValue !== dataRef.current.defaultValue);
        setFocusedIndex(-1);
      }
    },
    [onKeyPress]
  );
  const onFocus = () => {
    setFocused(true);
    document.addEventListener('click', onBlur);
    document.addEventListener('keydown', onKeyPress);
  };

  useEffect(() => {
    if (!isFocused) {
      document.removeEventListener('click', onBlur);
      document.removeEventListener('keydown', onKeyPress);
    }
  }, [isFocused, onBlur, onKeyPress]);

  return (
    <div css={labelWrapper}>
      <label htmlFor="address" css={label}>
        <span css={labelText}>Address</span>
        <div ref={addressEl} css={inputWrapper(isFocused, errorMsg && isInvalid)}>
          <input
            autoComplete="off"
            name="address"
            id="address"
            css={input}
            onInput={e => {
              onValidEntry((e.target as HTMLInputElement).value);
              onInput((e.target as HTMLInputElement).value);
            }}
            onFocus={onFocus}
            ref={inputEl}
            placeholder="Enter your Address"
            defaultValue={dataRef.current.defaultValue}
            data-testid="address-input"
          />
          <div css={menu(isOpen)} data-testid="address-menu">
            <div css={menuList} role="listbox">
              {options.data.map((option, i) => (
                <AddressOption
                  key={JSON.stringify(option)}
                  inputValue={inputEl.current?.value}
                  option={option}
                  index={i}
                  isFocused={focusedIndex === i}
                  onSelect={onSelect}
                  onHover={i => setFocusedIndex(i)}
                  isSelected={selected === getDefaultValueString(suggestionToAnswer(option))}
                />
              ))}
              {options.isLoading && <div css={loading}>Loading...</div>}
            </div>
          </div>
        </div>
      </label>
      {!!error && isInvalid && (
        <div data-testid="address-error" css={errorCSS}>
          {errorMsg}
        </div>
      )}
    </div>
  );
};

export default Address;
