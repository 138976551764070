/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

import React from 'react';
import { fakeInputCSS } from './FakeInput.style';

const FakeInput: React.FC<{text: string}> = ({text}) => {
  return (
    <div css={fakeInputCSS}>{text}</div>
  );
};

export default FakeInput;
