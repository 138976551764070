import { css, SerializedStyles } from '@emotion/react';

export const menu = (isOpen: boolean): SerializedStyles => css`
  background-color: #fff;
  border: solid 1px #e7e4e4;
  border-radius: 0 0 8px 8px;
  border-top: none;
  box-shadow: 0 4px 11px hsla(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: ${isOpen ? 'block' : 'none'};
  margin-top: -2px;
  padding-bottom: 4px;
  position: absolute;
  top: 82px;
  transition: border-color 0.2s ease, color 0.2s ease, box-shadow 0.2s ease;
  width: 100%;
  z-index: 2;
`;

export const menuList = css`
  border-top: solid 1px #e7e4e4;
  max-height: 400px;
  overflow: auto;
  padding-top: 10px;
`;

export const label = css`
  display: block;
  -webkit-appearance: none;
  width: 100%;
  box-sizing: border-box;
`;

export const labelWrapper = css`
  position: relative;
  padding-bottom: 18px;
`;

export const inputWrapper = (isFocused: boolean, hasError: boolean): SerializedStyles => css`
  border: 1px solid ${hasError ? '#C80000' : isFocused ? '#408DF7' : '#000'};
  min-height: 58px;
  transition: border-color 0.2s ease;
  border-radius: 4px;
  margin-top: 5px;
`;

export const labelText = css`
  color: #000;
  font-size: 16px;
`;

export const input = css`
  -webkit-appearance: none;
  width: 100%;
  padding-left: 20px;
  box-sizing: border-box;
  margin-top: 20px;
  font-variant-numeric: lining-nums;

  &,
  &:focus {
    border: none;
    background-color: transparent;
    outline: none;
  }
  
  &::placeholder {
    font-style: italic;
    font-weight: 400;
  }
`;

export const errorCSS = css`
  color: #C80000;
  font-size: 12px;
  position: absolute;
  bottom: 0;
`;

export const loading = css`
  color: #ccc9c8;
  padding: 8px 10px;
  text-align: center;
`;
