import axios from 'axios';

enum Methods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  HEAD = 'HEAD',
  DELETE = 'DELETE'
}

interface ApiCallParams {
  headers?: any;
  body?: any;
  params?: any;
}

type ApiCall = (url: string, params?: ApiCallParams) => Promise<any>;

interface API {
  get: ApiCall;
  post: ApiCall;
  put: ApiCall;
  patch: ApiCall;
  head: ApiCall;
  delete: ApiCall;
}

const call = (method: Methods, url: string, { headers = {}, body = {}, params = {} }: ApiCallParams = {}) =>
  axios({
    method,
    url,
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    data: JSON.stringify(body),
    params
  })
    .then(res => res.data)
    .catch(error => {
      if (!error.response) {
        window.location.reload();
      } else {
        throw error;
      }
    });

const api: API = {
  get(url, params = {}) {
    return call(Methods.GET, url, params);
  },
  post(url, params = {}) {
    return call(Methods.POST, url, params);
  },
  put(url, params = {}) {
    return call(Methods.PUT, url, params);
  },
  patch(url, params = {}) {
    return call(Methods.PATCH, url, params);
  },
  head(url, params = {}) {
    return call(Methods.HEAD, url, params);
  },
  delete(url, params = {}) {
    return call(Methods.DELETE, url, params);
  }
};

export default api;
