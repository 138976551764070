import axios from 'axios';

import { SMARTY_STREETS_WEB_KEY, SMARTY_STREETS_AUTOCOMPLETE_API } from '../constants/services';
import { SuggestionResponse } from '../interfaces/IStreets';

interface IStreetService {
  suggest(search: string): Promise<SuggestionResponse>;
  suggestSecondary(search: string, selected: string): Promise<SuggestionResponse>;
}

const streetService: IStreetService = {
  suggest: search =>
    axios.get(SMARTY_STREETS_AUTOCOMPLETE_API, {
      method: 'GET',
      params: {
        key: SMARTY_STREETS_WEB_KEY,
        include_only_states: 'allstates',
        search
      }
    }),
  suggestSecondary: (search, selected) =>
    axios.get(SMARTY_STREETS_AUTOCOMPLETE_API, {
      method: 'GET',
      params: {
        key: SMARTY_STREETS_WEB_KEY,
        include_only_states: 'allstates',
        search,
        selected
      }
    })
};

export default streetService;
