/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

import React from 'react';
import { CommonTextProps } from '../../interfaces/common.types';
import Copy from '../Copy/Copy';
import { heading } from '../Estimate/Estimate.style';
import { loanOfficersBlockCSS, paragraphCSS } from './LoanOfficersBlock.style';


const LoanOfficersBlock: React.FC<CommonTextProps> = ({ text }) => {
  return (
    <div css={loanOfficersBlockCSS}>
      <h2 css={heading}>Refer Matic</h2>
      <p css={paragraphCSS}>Copy and share this link.</p>
      <Copy text={text} />
    </div>
  );
};

export default LoanOfficersBlock;
