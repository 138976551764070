import { PartnerNames } from '../constants/partnerConfig';

const getLogo = (partnerKey: PartnerNames) => {
  switch (partnerKey) {
    case PartnerNames.NewAmericanFunding: 
      return {
        logoUrl: 'https://maticinsurance.sirv.com/partners/new_american_insurance.png',
        alt: 'NAF Logo'
      };
    default:
      return { 
        logoUrl: 'https://maticinsurance.sirv.com/partners/matic.png', 
        alt: 'Matic Logo' 
      };
  }
};

const getHeading = (partnerKey: PartnerNames) => {
  switch (partnerKey) {
    case PartnerNames.NewAmericanFunding: 
      return 'Estimate your home insurance';
    default:
      return 'Paying too much for homeowners insurance?';
  }
};


const getHeadingConfig = (partnerKey: PartnerNames) => {
  return {
    heading: getHeading(partnerKey),
    logo: getLogo(partnerKey)
  };
};

export default getHeadingConfig;
