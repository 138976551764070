export enum Validations {
  SquareFeet = 'square_feet',
  YearBuilt = 'year_built'
}

const VALIDATIONS = {
  [Validations.SquareFeet]: {
    valueAsNumber: true,
    pattern: {
      value: /^(0|[1-9][0-9]*)$/,
      message: 'Field can only contain integers'
    },
    min: {
      value: 100,
      message: 'Cannot be less than 100'
    },
    max: {
      value: 100000,
      message: 'Cannot be more than 100,000'
    }
  },
  [Validations.YearBuilt]: {
    valueAsNumber: true,
    pattern: {
      value: /^(0|[1-9][0-9]*)$/,
      message: 'Field can only contain integers'
    },
    min: {
      value: 1000,
      message: 'Cannot be less than 1000'
    }
  }
};

export default VALIDATIONS;
