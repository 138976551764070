import { css } from '@emotion/react';
import CSS from '../../constants/cssVariables';

export const estimateCSS = css`
  background-color: #f6f4f4;
  padding: 20px 0 9px;
  margin-top: 20px;
`;
export const heading = css`
  font-size: 16px;
  line-height: 1.25;
  font-weight: 700;
  margin: 0 0 11px;
  
  ${CSS.mq[0]} {
    font-size: 20px;
    line-height: 1.4;
  }
`;
export const headingInner = css`
  display: flex;
  align-items: center;
`;
export const wrapper = css`
  ${CSS.mq[0]} {
    display: flex;  
  }
`;
export const left = css`
  flex: 1 0 100%;
  
  ${CSS.mq[0]} {
    flex-basis: 360px;
  }

  ${CSS.mq[1]} {
    flex-basis: 402px;
  }
`;
export const right = css`
  flex: 0 1 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  
  ${CSS.mq[0]} {
    flex-basis: 50%;
    margin-left: 32px;
    margin-top: 0;
    margin-bottom: 0;
  }
`;
export const estimateItem = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
  position: relative;
`;
export const label = css`
  font-size: 16px;
  line-height: 1.25;
  padding-left: 28px;
  position: relative;

  ${CSS.mq[0]} {
    font-size: 20px;
    line-height: 1.4;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 55%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
`;
export const lowest = css`
  &:before {
    background-color: #408df7;
  }
`;
export const average = css`
  &:before {
    background-color: #6ee4d6;
  }
`;
export const highest = css`
  &:before {
    background-color: #fa7032;
  }
`;
export const value = css`
  font-size: 16px;
  line-height: 1.25;
  font-weight: bold;

  ${CSS.mq[0]} {
    font-size: 20px;
    line-height: 1.4;
  }
`;
export const tooltipTrigger = css`
  font-size: 12px;
  display: block;
  background-color: #C4C4C4;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  text-align: center;
  line-height: 16px;
  margin-left: 7px;
  font-weight: 400;
`;
export const tooltip = css`max-width: 200px; width: 100%; font-weight: 400;`;
