import { css  } from '@emotion/react';

export const fakeInputCSS = css`
  border: 1px solid #000;
  min-height: 58px;
  height: 100%;
  transition: border-color 0.2s ease;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
`;
