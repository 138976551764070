import { css } from '@emotion/react';

import CSS from '../../constants/cssVariables';

export const wrapper = css`
  display: flex;
  padding-top: 20px;
  flex-direction: column;
  
  ${CSS.mq[0]} {
    flex-direction: row;
    padding-top: 25px;
  }
`;

export const wrappedItem = css`
  flex-shrink: 1;
`;

export const mapWrapper = css`
  flex-basis: 50%;
  flex-shrink: 1;
  flex-grow: 0;
  order: 0;
  margin-left: 32px;
  display: none;
  
  ${CSS.mq[0]} {
    display: block;
  }
`;

export const formWrapper = css`
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-top: -4px;

  ${CSS.mq[0]} {
    flex-basis: 360px;
  }
  
  ${CSS.mq[1]} {
    flex-basis: 402px;
  }
`;
