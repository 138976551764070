/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';

import { jsx, css } from '@emotion/react';

import { button, hiddenForm } from './HiddenForm.style';
import analytics from '../../utils/analytics';
import { AddressAnswer } from '../../interfaces/IStreets';
import HiddenInput from '../HiddenInput/HiddenInput';

interface Props {
  partnerKey: string;
  address: AddressAnswer;
  CTAText: string;
  disclaimer?: string;
  placement?: string;
  prefillUrl: string;
}

const HiddenForm: React.FC<Props> = ({ disclaimer, CTAText, address, partnerKey, placement, prefillUrl }) => (
  <form
    target="_blank"
    css={hiddenForm}
    data-testid="hidden-form"
    method="POST"
    action={`${prefillUrl}/prefill/homes`}
    onSubmit={() => analytics.track('Carrier Comparison Initiated', {
      partner_key: partnerKey,
      placement
    })}
  >
    <HiddenInput name="partner_key" value={partnerKey} />
    <HiddenInput name="[address][line1]" value={address.address1} />
    <HiddenInput name="[address][city]" value={address.city} />
    <HiddenInput name="[address][state]" value={address.state} />
    <HiddenInput name="[address][zip]" value={address.zip} />
    <HiddenInput name="[tracking_params][source]" value="widget" />
    <HiddenInput name="[tracking_params][source_placement]" value={placement || ''} />

    <button
      css={button}
    >
      {CTAText}
    </button>
    {disclaimer && (
      <div
        css={css`
                margin-top: 8px;
                text-align: center;
              `}
      >
        {disclaimer}
      </div>
    )}
  </form>
);

export default HiddenForm;
