/** @jsxRuntime classic */
/** @jsx jsx */
import './Slider.css';

import React, { useState } from 'react';

import ReactSlider from 'react-slider';
import { jsx, css, SerializedStyles } from '@emotion/react';
import CSS from '../../constants/cssVariables';

interface Props {
  onChange(value: number): void;
  value: number;
  isDisabled?: boolean;
  description: string
}

const wrapper = css`
  margin-top: 16px;
`;

const headingRow = css`
  display: flex;
  justify-content: space-between;
`;

const heading = css`
  font-size: 16px;
  line-height: 1.25;
  font-weight: bold;
  margin-top: 0;
`;

const valueResult = css`
  font-size: 16px;
  line-height: 1.25;
`;

const slider = (isDisabled: boolean): SerializedStyles => css`
  opacity: ${isDisabled ? 0.65 : 1};
  cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
  display: flex;
  margin-top: 14px;
  margin-right: 8px;
  
  ${CSS.mq[0]} {
    margin-right: 0;
  }
`;

const prepended = css`
  flex: 1 1 25%;
  height: 12px;
  background-color: #bbf0ea;
`;

const FICO_BUCKETS = [
  {
    name: 'Poor',
    range: '(300 - 629)',
    value: 525
  },
  {
    name: 'Fair',
    range: '(630 - 689)',
    value: 650
  },
  {
    name: 'Good',
    range: '(690 - 719)',
    value: 719
  },
  {
    name: 'Excellent',
    range: '(720 - 850)',
    value: 800
  }
];
const MARKS_AMOUNT = FICO_BUCKETS.length - 1;

const Slider: React.FC<Props> = ({ onChange, value: defaultValue, isDisabled = false, description }) => {
  const [value, setValue] = useState(FICO_BUCKETS.map(fb => fb.value).indexOf(defaultValue));
  const onSliderChange = (value?: number | number[] | null) => {
    setValue(value as number);
    onChange(FICO_BUCKETS[value as number].value);
  };
  return (
    <div css={wrapper}>
      <div css={headingRow}>
        <div css={heading}>{description}</div>
        <div css={valueResult}>
          {FICO_BUCKETS[value].name} {FICO_BUCKETS[value].range}
        </div>
      </div>
      <div css={slider(isDisabled)}>
        <div css={prepended} onClick={() => onSliderChange(0)} />
        <ReactSlider
          marks
          min={0}
          value={value}
          max={MARKS_AMOUNT}
          onAfterChange={onSliderChange}
          disabled={isDisabled}
          renderTrack={props =>
            props.key === 'track-0' ? (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: 12,
                  right: `${100 - (value / MARKS_AMOUNT) * 100}%`
                }}
              />
            ) : (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: 12,
                  left: `${(value / MARKS_AMOUNT) * 100}%`
                }}
              />
            )
          }
          renderThumb={props => (
            <div
              {...props}
              style={{
                ...props.style,
                left: `${((props['aria-valuenow'] as number) / MARKS_AMOUNT) * 100}%`
              }}
            />
          )}
          renderMark={({ className, key, style }) => (
            <span
              className={className}
              key={key}
              style={{
                ...style,
                left: `${((key as number) / MARKS_AMOUNT) * 100}%`
              }}
            />
          )}
        />
      </div>
    </div>
  );
};

export default Slider;
