export enum PartnerNames {
  NerdWallet = 'nerd_wallet',
  Matic = 'matic',
  FreedomMortgage = 'freedom',
  Gravy = 'gravy',
  DwellWell = 'dwellwell',
  DotDash ='dotdash',
  DartBank='dart_bank',
  NewAmericanFunding='new_american_funding' // NAF
}
