/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useState, Fragment } from 'react';

import { jsx } from '@emotion/react';
import { useForm, Controller } from 'react-hook-form';
import debounce from 'debounce-promise';

import { formWrapper, mapWrapper, wrappedItem, wrapper } from './Form.style';
import Address from '../Address/Address';
import Slider from '../Slider/Slider';
import AddressMap from '../Address/AddressMap';
import Container from '../Container/Container';
import InputGroup from '../InputGroup/InputGroup';
import estimateService, { Estimate as IEstimate } from '../../services/estimate';
import { AddressAnswer } from '../../interfaces/IStreets';
import Estimate from '../Estimate/Estimate';
import VALIDATIONS, { Validations } from '../../constants/validations';
import analytics from '../../utils/analytics';
import HiddenForm from '../HiddenForm/HiddenForm';
import { getFormConfigParams, subDomainResolver } from '../../utils/config';
import LoanOfficersBlock from '../LoanOfficersBlock/LoanOfficersBlock';
import { PartnerConfig } from '../../services/config';
import { PartnerNames } from '../../constants/partnerConfig';
import SEGMENT from '../../constants/segment';

interface FormValues {
  property: {
    address: AddressAnswer;
    year_built?: number | null;
    square_feet?: number | null;
  };
  customer: {
    fico_score: number;
  };
}

const DEFAULT_VALUES = {
  property: {
    address: {
      address1: '',
      city: '',
      state: '',
      zip: ''
    },
    year_built: null,
    square_feet: null
  },
  customer: {
    fico_score: 719
  }
};

const LINK = 'https://freedom-mortgage-ds.matic.com/home';
const isFreedomLoanOfficer = subDomainResolver() === PartnerNames.FreedomMortgage;

const Form: React.FC<{partnerConfig?: PartnerConfig, prefillUrl?: string}> = ({ partnerConfig, prefillUrl }) => {
  const { partner_key, placement } = getFormConfigParams();

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<FormValues>({
    defaultValues: DEFAULT_VALUES,
    shouldFocusError: false
  });
  const [addressSubmitValue, setAddressSubmitValue] = useState<AddressAnswer>(DEFAULT_VALUES.property.address);
  const [estimate, setEstimate] = useState<IEstimate | null>(null);
  const [isPremiumLoading, setIsPremiumLoading] = useState(false);
  const [isAllLoading, setIsAllLoading] = useState(false);
  const isAnyLoading = isPremiumLoading || isAllLoading;
  const CTAText = 'Compare Carriers';

  const onSubmit =
    (isAddressChange = false) =>
    async ({ customer, property }: any) => {
      if (!property.address?.address1) return;
      const dataToSend = {
        partner_key,
        customer,
        property: { ...property, square_feet: +property.square_feet || null, year_built: +property.year_built || null }
      };
      isAddressChange ? setIsAllLoading(true) : setIsPremiumLoading(true);
      analytics.track(SEGMENT.TRACKS.ESTIMATES_REQUESTED, { partner_key, placement });

      const { data } = await estimateService.getEstimate(dataToSend);

      if (isAddressChange) {
        setIsAllLoading(false);
        partnerConfig?.prefill && setAddressSubmitValue(property.address);
      } else {
        setIsPremiumLoading(false);
      }

      setEstimate(data.estimate.premium);
      setValue('property.square_feet', data.property.square_feet || null);
      setValue('property.year_built', data.property.year_built || null);
    };

  const controllerOnChange =
    (onChange: (value: any) => void, isAddressChange = false) =>
    (value: any) => {
      onChange(value);
      if (isAddressChange) {
        setValue('property.square_feet', null);
        setValue('property.year_built', null);
      }
      handleSubmit(onSubmit(isAddressChange))();
    };

  return (
    <Fragment>
      <Container>
        <div css={wrapper}>
          <form css={[wrappedItem, formWrapper]} onSubmit={e => e.preventDefault()}>
            <div>
              <Controller
                name="property.address"
                rules={{
                  validate: (value: AddressAnswer) => (value && !!value.address1) || 'Please enter a valid address.'
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Address
                    onSelectEntry={e => {
                      onChange(e);
                      handleSubmit(onSubmit(true))(); 
                    }}
                    onValidEntry={controllerOnChange(onChange, true)}
                    error={(errors.property?.address as any)?.message}
                    value={value}
                  />
                )}
              />

              <InputGroup
                isDisabled={isAnyLoading}
                label="Year Built"
                value={getValues().property?.year_built}
                errorMessage={errors.property?.year_built?.message}
                isLoading={isAllLoading}
                {...register('property.year_built', {
                  ...VALIDATIONS[Validations.YearBuilt],
                  validate: value =>
                    value === null || (!!value && value) as number <= new Date().getFullYear() || 'Must be current year or older',
                  onChange: debounce(handleSubmit(onSubmit()), 1200)
                })}
                name={'property.year_built'}
              />

              <InputGroup
                isDisabled={isAnyLoading}
                label="Square Feet"
                mobileLabel="Sq. Feet"
                value={getValues().property?.square_feet}
                errorMessage={errors.property?.square_feet?.message}
                isLoading={isAllLoading}
                {...register('property.square_feet', {
                  ...VALIDATIONS[Validations.SquareFeet],
                  onChange: debounce(handleSubmit(onSubmit()), 1200)
                })}
                name={'property.square_feet'}
              />
            </div>

            <div>
              <Controller
                name="customer.fico_score"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Slider 
                    onChange={controllerOnChange(onChange)} 
                    value={value} 
                    isDisabled={isAnyLoading} 
                    description={isFreedomLoanOfficer ? 'Borrower’s Credit Rating' : 'My Credit Rating'} 
                  />
                )}
              />
            </div>
          </form>

          <div css={[wrappedItem, mapWrapper]}>
            <AddressMap address={getValues().property?.address} />
          </div>
        </div>
      </Container>

      <Estimate estimate={estimate} isLoading={isAllLoading || isPremiumLoading} CTAText={CTAText}>
        {
          isFreedomLoanOfficer ?
            <LoanOfficersBlock text={LINK} />
          :
            <HiddenForm
              partnerKey={partner_key}
              placement={placement}
              disclaimer={partnerConfig?.disclaimer}
              address={addressSubmitValue}
              CTAText={CTAText}
              prefillUrl={prefillUrl || ''}
            />
        }
      </Estimate>
    </Fragment>
  );
};

export default Form;
