const containerMaxWidth = 1140;
const containerPadding = 32;
const containerPaddingMobile = 12;

const breakpoints = [700, containerMaxWidth + containerPadding * 2];

const mq = breakpoints.map(
  bp => `@media (min-width: ${bp}px)`
);

const CSS = {
  containerMaxWidth,
  containerPadding,
  containerPaddingMobile,
  mq,
  grayLight: '#F6F4F4'
};

export default CSS;
