import { css } from '@emotion/react';

import CSS from '../../constants/cssVariables';

export const copyContainerCSS = css`
  display: flex;  
  height: 60px;
  width: 100%;
`;

export const buttonCSS = css`
  -webkit-appearance: none;
  color: #fff;
  background-color: #156EEA;
  transition: background-color 0.2s ease;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.25;
  font-weight: bold;
  padding: 0 20px;
  cursor: pointer;
  outline: none;
  display: block;
  margin-left:8px;
  height: 100%;

  ${CSS.mq[0]} {
    font-size: 20px;
    line-height: 1.4;
  }
  
  &:hover, &:focus {
    background-color: #408DF7;
  }
`;
