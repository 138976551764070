import { PartnerNames } from '../constants/partnerConfig';

export const subDomainResolver = (): string | false => {
  const subdomain = window.location.hostname.split('.')[0];
  const isAllowedSubdomain = Object.values(PartnerNames).includes(subdomain as PartnerNames);

  return isAllowedSubdomain ? subdomain : false;
};

export const getPartnerKey = (params: URLSearchParams): string | null => {
  const subdomain = subDomainResolver();

  return subdomain ? subdomain : params.get('partner_key');
};

export const getPlacement = (params: URLSearchParams): string | null => {
  const subdomain = subDomainResolver();

  return subdomain ? window.location.href : params.get('placement');
};

export const getConfigParams = (): { partner_key: string | null; placement: string | null } => {
  const params = new URLSearchParams(window.location.search);

  const partner_key = getPartnerKey(params);
  const placement = getPlacement(params);

  return { partner_key, placement };
};

export const getFormConfigParams = (): { partner_key: string | PartnerNames; placement: string | undefined } => {
  const { partner_key, placement } = getConfigParams();

  return {
    partner_key: partner_key || PartnerNames.Matic,
    placement: placement || undefined
  };
};
