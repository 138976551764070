import React, { useEffect, useState } from 'react';

import { Global, css } from '@emotion/react';

import Header from './components/Header/Header';
import Form from './components/Form/Form';
import analytics from './utils/analytics';
import { getConfigParams } from './utils/config';
import configService, { ConfigResponse } from './services/config';
import SEGMENT from './constants/segment';

const App: React.FC = () => {
  const { partner_key, placement } = getConfigParams();
  const [config, setConfig] = useState<ConfigResponse | undefined>();

  useEffect(() => {
    if (config?.segment) {
      analytics.init(config.segment);

      analytics.page(SEGMENT.PAGES.HOME, { partner_key, placement });
    }
  }, [partner_key, placement, config?.segment]);

  useEffect(() => {
    (async () => {
      const appConfig = await configService.getConfig(partner_key || '');
      setConfig(appConfig);
    })();
  }, [partner_key]);

  return (
    <>
      <Global
        styles={css`
          body {
            font-family: 'freight-sans-pro', sans-serif;
            font-size: 16px;
            font-variant-numeric: lining-nums;
          }
        `}
      />
      <Header />
      <main>
        <Form partnerConfig={config?.partner} prefillUrl={config?.prefill_url} />
      </main>
    </>
  );
};

export default App;
