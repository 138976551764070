import { css } from '@emotion/react';

import CSS from '../../constants/cssVariables';

export const loanOfficersBlockCSS = css`
  padding-right: 11px;
  width: 100%;
  margin-top: 24px;

  ${CSS.mq[0]} {
    margin-top: 0;
    padding-right: 32px;
  }
`;

export const paragraphCSS = css`
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
`;
