/** @jsxRuntime classic */
/** @jsx jsx */
import React, { useEffect, useRef, useState } from 'react';

import Geocode from 'react-geocode';
import { jsx, css } from '@emotion/react';

import { AddressSuggestion, DetailedAddress, AddressAnswer } from '../../interfaces/IStreets';
import { GOOGLE_API_KEY, GOOGLE_MAPS_SCRIPT } from '../../constants/services';
import MAP_CONFIG from './mapConfig';

Geocode.setApiKey(GOOGLE_API_KEY);

const answerToSuggestion = (answer: AddressAnswer): AddressSuggestion | null =>
  answer.address1
    ? {
        street_line: answer.address1,
        secondary: '',
        city: answer.city,
        state: answer.state,
        zipcode: answer.zip,
        entries: '0'
      }
    : null;

interface MapInstance {
  map: any;
  marker: any;
}

interface Props {
  address?: AddressAnswer | null;
}

const AddressMap: React.FC<Props> = ({ address = null }) => {
  const mapInstance = useRef<MapInstance>({ map: null, marker: null });
  const [mapsReady, setReady] = useState<boolean | null>(!!(window as any).google ? false : null);
  const [detailedAddress, setDetailedAddress] = useState<DetailedAddress | null>(null);

  const initMapInstance = () => {
    const map = new (window as any).google.maps.Map(document.getElementById('map'), MAP_CONFIG);
    const marker = new (window as any).google.maps.Marker({ map });
    mapInstance.current.map = map;
    mapInstance.current.marker = marker;

    setReady(true);
  };
  const addGoogleMapsScript = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.defer = true;
    script.src = GOOGLE_MAPS_SCRIPT;
    script.onload = initMapInstance;
    document.head.appendChild(script);
  };
  const getDetailedAddress = async ({ street_line, city, state, zipcode }: AddressSuggestion) => {
    const { results } = await Geocode.fromAddress(`${street_line} ${city} ${state} ${zipcode}`);

    if (results.length) {
      setDetailedAddress(results[0].geometry.location);
    } else {
      throw new Error('Address from SmartyStreets was not found in Google Maps Geocode API.');
    }
  };
  const setMapLocation = () => {
    if (detailedAddress) {
      (mapInstance.current.map as any).setCenter(detailedAddress!);
      (mapInstance.current.marker as any).setPosition(detailedAddress);
    }
  };

  useEffect(() => {
    mapsReady === null && addGoogleMapsScript();
    mapsReady === false && initMapInstance();
    detailedAddress && mapsReady && setMapLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapsReady, detailedAddress]);

  useEffect(() => {
    if (address && answerToSuggestion(address)) {
      getDetailedAddress(answerToSuggestion(address) as AddressSuggestion);
    }
  }, [address]);

  return (
    <div data-testid="address-map-frame">
      <div
        css={css`
          height: 239px;
        `}
        id="map"
        data-testid="address-map"
        role="presentation"
        aria-hidden="true"
      />
    </div>
  );
};

export default AddressMap;
