/** @jsxRuntime classic */
/** @jsx jsx */

import React from 'react';

import { jsx, css } from '@emotion/react';

import CSS from '../../constants/cssVariables';

const style = css`
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  max-width: ${CSS.containerMaxWidth}px;
  padding-left: ${CSS.containerPaddingMobile}px;
  padding-right: ${CSS.containerPaddingMobile}px;
  
  ${CSS.mq[0]} {
    padding-left: ${CSS.containerPadding}px;
    padding-right: ${CSS.containerPadding}px;
  }

  ${CSS.mq[1]} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Container: React.FC = ({ children }) => <div css={style}>{children}</div>;

export default Container;
