import currencyFormatter from 'currency-formatter';

type MoneyFormatter = (
  value: string | number,
  cutFromK?: number,
  prefix?: string | null,
  suffix?: string | null
) => string | undefined;

export const money: MoneyFormatter = (value, cutFromK = 0, prefix = '', suffix = '') => {
  prefix = prefix === null ? '' : prefix;
  suffix = suffix === null ? '' : suffix;

  if (typeof value === 'number' || !Number.isNaN(Number.parseInt(value))) {
    const thousands = +value / 1000;

    if (cutFromK && thousands >= cutFromK) {
      const precision = thousands % 1 > 0.099 ? 1 : 0;
      return `${prefix}${currencyFormatter.format(thousands, { precision })}k${suffix}`;
    }

    return `${prefix}${currencyFormatter.format(Math.round(+value), { precision: 0 })}${suffix}`;
  }
};
