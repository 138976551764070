import api from './api';

export type PartnerConfig = {
  disclaimer: string;
  prefill: boolean;
}

export interface ConfigResponse {
  segment: string;
  partner: PartnerConfig;
  prefill_url: string;
}

const configService = {
  getConfig(partner?: string): Promise<ConfigResponse> {
    return api.get(`/api/config?partner_key=${partner}`);
  }
};

export default configService;
