import { css } from '@emotion/react';

import CSS from '../../constants/cssVariables';

export const button = css`
  -webkit-appearance: none;
  color: #fff;
  background-color: #408DF7;
  transition: background-color 0.2s ease;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 16px;
  line-height: 1.25;
  height: 44px;
  font-weight: bold;
  padding: 0 20px;
  cursor: pointer;
  outline: none;
  display: block;
  margin: 0 auto;

  ${CSS.mq[0]} {
    font-size: 20px;
    line-height: 1.4;
    height: 68px;
    margin-top: 40px;
  }
  
  &:hover, &:focus {
    background-color: #2c79e3;
  }
`;

export const hiddenForm = css`
  max-width: 300px;
  margin: 0 auto;
  font-size: 16px;
`;
