import api from './api';

export interface Estimate {
  avg: number;
  min: number;
  max: number;
}

interface EstimatePayload {
  customer: {
    fico_score: number;
  };
  property: {
    address: {
      address1: string;
      city: string;
      state: string;
      zip: string;
    };
    year_built?: number | null;
    square_feet?: number | null;
  };
}

interface EstimateResponse {
  data: {
    estimate: {
      premium: Estimate;
    };
    property: {
      square_feet: number;
      year_built: number;
    }
  };
}

const estimateService = {
  getEstimate(payload: EstimatePayload): Promise<EstimateResponse> {
    return api.post('/api/estimate', { body: { ...payload } });
  }
};

export default estimateService;
