import { css, SerializedStyles } from '@emotion/react';

export const option = (isFocused: boolean): SerializedStyles => css`
  background-color: ${isFocused ? '#f3f2f2' : 'transparent'};
  border: none;
  color: #726969;
  cursor: pointer;
  font-weight: 200;
  outline: none;
  overflow: hidden;
  padding: 8px 16px 8px 12px;
  position: relative;
  text-align: left;
  width: 100%;
  font-variant-numeric: lining-nums;

  &:hover {
    background-color: #f3f2f2;
  }
`;

export const entries = css`
  color: #4076b2;
`;

export const caret = css`
  height: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
`;

export const label = css`
  color: #000;
  font-weight: bold;
`;
